// This component allows to build a Hasura GraphQL query
// by clicking on a few buttons
// It reads the schema from the Hasura API and builds
// the interface based on that
// Language: typescript

import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import Filter from './Filter';
import schema from '../graphql.schema.json';

const fields = schema.__schema.types
  .filter((t) => t.name === 'properties')[0]
  .fields?.map((f) =>
    ['SCALAR', 'OBJECT'].includes(f.type.kind)
      ? { name: f.name, type: f.type.name }
      : undefined
  )
  .filter((f) => f) as { name: string; type: string }[];

const operators = [
  '_eq',
  '_neq',
  '_gt',
  '_gte',
  '_lt',
  '_lte',
  '_like',
  '_ilike',
  '_is_null',
  '_in',
  '_nin',
];

const Filters = () => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState<any[]>([]);

  const w = new URLSearchParams(location.search).get('where');

  useEffect(() => {
    if (w) {
      try {
        const query = JSON.parse(w);
        const filters = Object.keys(query).map((f) => ({
          field: f,
          operator: Object.keys(query[f])[0],
          value: query[f][Object.keys(query[f])[0]],
        }));
        console.log(filters);
        setFilters(filters);
      } catch (e) {
        console.log(e);
        message.error('Filters: Invalid query in URL');
      }
    } else {
      setFilters([]);
    }
  }, [w]);

  const onChange = ({
    field,
    operator,
    value,
  }: {
    field?: string;
    operator?: string;
    value?: string | string[];
  }) => {
    const where = [...filters, { field, operator, value }].reduce((acc, f) => {
      // If onChange is called with empty operator, we remove the filter
      if (!f.operator) {
        delete acc[f.field];
        return acc;
      }
      // Else we add the filter
      acc[f.field] = { [f.operator]: f.value };
      return acc;
    }, {});
    Object.keys(where).length
      ? history.push(
          `/properties?where=${encodeURIComponent(JSON.stringify(where))}`
        )
      : history.push('/properties');
  };

  return (
    <>
      {filters.map((f) => (
        <Filter
          key={f.field}
          field={f.field}
          operator={f.operator}
          value={f.value}
          fields={fields}
          operators={operators}
          onChange={onChange}
        />
      ))}
      <Filter fields={fields} operators={operators} onChange={onChange} />
    </>
  );
};

export default Filters;

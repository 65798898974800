import React from 'react';
import { Typography, Space, Card } from 'antd';
import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import UserSelect from '../../components/UserSelect';
import { UPDATE_PROPERTY } from '../../graphql/property';
import { GetPropertyQuery } from '../../generated/graphql';

type Property = NonNullable<GetPropertyQuery['properties_by_pk']>;

const { Title } = Typography;

const formatEstimateValue = (number: any) =>
  number ? `$${Intl.NumberFormat('en-US').format(number)}` : '-';

const realtorServiceFilter = (name: string, estimates: any) =>
  estimates?.filter((estimate: any) => estimate.source.type === name);

const realtorServiceEstimate = (name: string, estimates: any) => {
  const estimate = realtorServiceFilter(name, estimates);
  if (estimate && estimate.length) {
    return estimate[0].estimates[0].estimate;
  }
  return null;
};

interface DetailsProps {
  property: Property;
}

const Details = ({ property: p }: DetailsProps) => {
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const { user } = useAuth0();

  const contactDetails = [
    ['Given Name', p.given_name],
    ['Family Name', p.family_name],
    ['Phone', p.mobile],
    ['Email', p.email],
    [
      'Reason',
      [p.user_relation, p.appraisal_reason, p.sale_horizon]
        .filter((v) => v)
        .join(' → '),
    ],
    ['Horizon', p.sale_horizon],
  ];

  const propertyDetails = [
    ['Perceived value', formatEstimateValue(p.perceived_value)],
    ['Estimate median', formatEstimateValue(p.value_estimate)],
    [
      'Estimate low-high',
      [p.low_estimate, p.high_estimate]
        .map((v) => formatEstimateValue(v))
        .filter((v) => v)
        .join(' - '),
    ],
    [
      'Estated',
      formatEstimateValue(p.estateds[0]?.response?.data?.valuation?.value),
    ],

    [
      'Collateral Analytics',
      formatEstimateValue(
        realtorServiceEstimate(
          'collateral',
          p.realtors[0]?.response?.data?.estimates
        )
      ),
    ],
    [
      'CoreLogic®',
      formatEstimateValue(
        realtorServiceEstimate(
          'corelogic',
          p.realtors[0]?.response?.data?.estimates
        )
      ),
    ],
    [
      'Quantarium',
      formatEstimateValue(
        realtorServiceEstimate(
          'quantarium',
          p.realtors[0]?.response?.data?.estimates
        )
      ),
    ],
    [
      'Zestimate®',
      formatEstimateValue(p.zillows[0]?.response?.data?.zEstimate),
    ],
    ['Address', [p.street_number, p.route].filter((v) => v).join(' ')],
    ['Unit', p.unit],
    ['City', p.locality],
    ['ZIP', p.postal_code],
    ['State', p.state],
    ['Type', p.property_type],
    ['Occupation', p.occupation_type],
  ];

  const marketingDetails = [
    ['landing_page', p.landing_page_url],
    ['referrer', p.referrer_url],
    ['user_agent', p.navigator_user_agent],
    ['ip', p.ip],
    ['utm_medium', p.utm_medium],
    ['utm_source', p.utm_source],
    ['utm_campaign', p.utm_campaign],
    ['utm_term', p.utm_term],
    ['utm_content', p.utm_content],
    ['fbclid', p.fbclid],
    // eslint-disable-next-line
    ['_fbp', p._fbp],
    // eslint-disable-next-line
    ['_fbc', p._fbc],
    ['gclid', p.gclid],
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={2}>
        {[p.given_name, p.family_name].filter((v) => v).join(' ')}
      </Title>
      <Title level={5}>{p.formatted_address}</Title>
      <Card size="small" title="Lead" className="card-table">
        <table className="card-table">
          <tbody>
            <tr>
              <th>Agent</th>
              <td>
                <UserSelect
                  onChange={(v: any) => {
                    updateProperty({
                      variables: {
                        id: p.id,
                        changes: {
                          agent_user_id: v.value || null,
                        },
                      },
                    });
                  }}
                  defaultValue={
                    p.agent
                      ? {
                          label: p.agent.name,
                          value: p.agent.id,
                        }
                      : null
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
      <Card size="small" title="Contact" className="card-table">
        <table className="card-table">
          <tbody>
            {contactDetails.map((d) => (
              <tr key={d[0]}>
                <th>{d[0]}</th>
                <td>{d[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      <Card size="small" title="Property" className="card-table">
        <table className="card-table">
          <tbody>
            {propertyDetails.map((d) => (
              <tr key={d[0]}>
                <th>{d[0]}</th>
                <td>{d[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      {user?.['https://hasura.io/jwt/claims']['x-hasura-role'] === 'admin' && (
        <Card size="small" title="Marketing" className="card-table">
          <table className="card-table">
            <tbody>
              {marketingDetails.map((d) => (
                <tr key={d[0]}>
                  <th>{d[0]}</th>
                  <td>{d[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      )}
    </Space>
  );
};

export default Details;

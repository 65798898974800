import { useQuery } from '@apollo/client';
import { Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { GET_TEAMS } from '../graphql/team';
import { GetTeamsQuery } from '../generated/graphql';
type Team = GetTeamsQuery['teams'][0];

const columns = [
  {
    title: 'Created',
    dataIndex: 'created_at',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Members',
    dataIndex: ['users_teams_aggregate', 'aggregate', 'count'],
  },
];

const Teams = () => {
  const { loading, error, data } = useQuery<GetTeamsQuery>(GET_TEAMS);
  const history = useHistory();

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const onClick = (r: Team) => history.push(`/teams/${r.id}`);

  return (
    <>
      <Button
        onClick={() => history.push(`/teams/create`)}
        type="primary"
        style={{ margin: 10 }}
      >
        Create team
      </Button>
      <div style={{ overflowX: 'scroll' }}>
        <Table
          style={{ cursor: 'pointer', minWidth: 800 }}
          columns={columns}
          dataSource={data?.teams}
          loading={loading}
          rowKey="id"
          pagination={{
            pageSize: 100,
          }}
          onRow={(r) => ({
            onClick: () => onClick(r),
          })}
        />
      </div>
    </>
  );
};

export default Teams;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Profile from './Profile';
import ActivityTypes from './ActivityTypes';
import AgentsMap from './AgentsMap';

const Settings = () => {
  return (
    <Switch>
      <Route path="/settings/profile">
        <Profile />
      </Route>
      <Route path="/settings/activities">
        <ActivityTypes />
      </Route>
      <Route path="/settings/agents-map">
        <AgentsMap />
      </Route>
      <Route path="/settings">
        <Redirect to="/settings/profile" />
      </Route>
    </Switch>
  );
};

export default Settings;

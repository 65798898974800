import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const key = process.env.REACT_APP_GMAP_KEY || '';
const containerStyle = {
  width: '100%',
  height: '300px',
  backgroundColor: '#dddddd',
};

interface MapProps {
  loading: boolean;
  lat: number;
  lng: number;
}

const Map = ({ loading, lat, lng }: MapProps) => {
  if (loading) return <div style={containerStyle} />;
  return (
    <LoadScript googleMapsApiKey={key}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={19}
        mapTypeId="satellite"
      >
        <Marker position={{ lat, lng }} />
        <></>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;

import { Select } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { GET_USERS } from '../graphql/user';
import { GetUsersQuery } from '../generated/graphql';

const { Option } = Select;

interface UserSelectProps {
  defaultValue?: any;
  onChange?: (value: string) => void;
}

const UserSelect = ({ onChange, defaultValue }: UserSelectProps) => {
  const [getUsers, { data }] = useLazyQuery<GetUsersQuery>(GET_USERS);

  const handleSearch = (v: string) => {
    getUsers({
      variables: {
        search: `%${v}%`,
      },
    });
  };

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      placeholder="Select an agent"
      filterOption={false}
      optionFilterProp="children"
      defaultValue={defaultValue}
      labelInValue
      onSearch={handleSearch}
      onChange={onChange}
      allowClear
    >
      {data?.users.map((u) => (
        <Option value={u.id} key={u.id}>
          <strong>{u.name}</strong> {u.email}
        </Option>
      ))}
    </Select>
  );
};

export default UserSelect;

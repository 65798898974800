import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Popconfirm,
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Table,
} from 'antd';

import {
  GET_ACTIVITY_TYPES,
  INSERT_ACTIVITY_TYPE,
  DELETE_ACTIVITY_TYPE,
} from '../../graphql/activityType';
import { GetActivityTypesQuery } from '../../generated/graphql';

function ActivityTypes() {
  const { loading, error, data } =
    useQuery<GetActivityTypesQuery>(GET_ACTIVITY_TYPES);
  const [insertActivityType] = useMutation(INSERT_ACTIVITY_TYPE, {
    refetchQueries: [{ query: GET_ACTIVITY_TYPES }],
  });
  const [deleteActivityType] = useMutation(DELETE_ACTIVITY_TYPE, {
    refetchQueries: [{ query: GET_ACTIVITY_TYPES }],
  });
  const [visible, setVisible] = useState(false);
  const [creationError, setCreationError] = useState(null);
  const [form] = Form.useForm();

  const fields = [
    {
      label: 'Id',
      name: 'id',
      tooltip: 'A technical id',
      required: true,
      errorMessage: 'Please chose a technical id',
      placeholder: 'business-lunch',
    },
    {
      label: 'Label',
      name: 'label',
      tooltip: 'The display label',
      required: true,
      errorMessage: 'Please chose a label to display',
      placeholder: 'Business lunch',
    },
    {
      label: 'Emoji',
      name: 'emoji',
      tooltip: 'A visual representation for your activity type',
      required: true,
      errorMessage: 'Please chose an emoji',
      placeholder: '🍱',
    },
  ];

  const columns: any = [];

  columns.push({
    title: 'Created',
    dataIndex: 'created_at',
  });

  fields.map((f) =>
    columns.push({
      title: f.label,
      dataIndex: f.name,
    })
  );

  columns.push({
    title: 'Operations',
    dataIndex: 'operations',
    render: (_: any, record: any) => (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => deleteActivityType({ variables: { id: record.id } })}
      >
        <Button>Delete</Button>
      </Popconfirm>
    ),
  });

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const { Title } = Typography;

  const onCreate = (values: any) => {
    const val = Object.fromEntries(
      Object.entries(values).filter(([, v]: any) => v.trim())
    );
    insertActivityType({
      variables: {
        object: {
          ...val,
        },
      },
    })
      .then(() => {
        form.resetFields();
        setVisible(false);
      })
      .catch((err) => setCreationError(err));
  };

  return (
    <>
      <Modal
        visible={visible}
        title="Create new activity type"
        okText="Save now"
        cancelText="Cancel"
        onOk={() => {
          form.validateFields().then(onCreate);
        }}
        onCancel={() => setVisible(false)}
      >
        {creationError && <pre>{JSON.stringify(creationError, null, 2)}</pre>}
        <Form layout="vertical" form={form}>
          {fields.map((f) => (
            <Form.Item
              label={f.label}
              name={f.name}
              rules={[
                {
                  required: f.required,
                  message: f.errorMessage,
                },
              ]}
              tooltip={f.tooltip}
            >
              <Input placeholder={f.placeholder} />
            </Form.Item>
          ))}
        </Form>
      </Modal>
      <div style={{ padding: 16 }}>
        <Button
          type="primary"
          style={{ float: 'right' }}
          onClick={() => setVisible(true)}
        >
          New activity type
        </Button>
        <Title level={1}>Activity types</Title>
        <Table
          columns={columns}
          dataSource={data?.activity_types}
          rowKey="id"
          loading={loading}
        />
      </div>
    </>
  );
}

export default ActivityTypes;

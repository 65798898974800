import { useState } from 'react';
import { useParams, useHistory, Link, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Row, Col, Card, Input, Button, Form, Tag, Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Feed from './Feed';
import Drawer from '../../components/Drawer';
import ZipCodeSelect from '../../components/ZipCodesSelect';

import { GET_USER, UPDATE_USER } from '../../graphql/user';
import { ADD_ZIP_CODE, DELETE_ZIP_CODE } from '../../graphql/zipCodes';
import { GetUserQuery, UpdateUserMutation } from '../../generated/graphql';
import Zip from '../Zip/Zip';
type User = NonNullable<GetUserQuery['users_by_pk']>;

const User = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [drawerVisible, setDrawerVisible] = useState(true);
  const [edit, setEdit] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateUser] = useMutation(UPDATE_USER);
  const [addZipCode] = useMutation(ADD_ZIP_CODE);
  const [deleteZipCode] = useMutation(DELETE_ZIP_CODE);
  const { error, data, loading } = useQuery<GetUserQuery>(GET_USER, {
    variables: { id },
  });

  const user = data?.users_by_pk;
  const zipCodes = user?.zip_codes.map((z) => z.zip_code);

  const onSelect = (v: any) => {
    if (v) {
      addZipCode({
        variables: {
          user_id: user?.id,
          zip_code_id: v.id,
        },
        refetchQueries: [{ query: GET_USER, variables: { id } }],
      });
    }
  };

  const onDeselect = (v: any) => {
    if (v) {
      deleteZipCode({
        variables: {
          user_id: user?.id,
          zip_code_id: v.id,
        },
        refetchQueries: [{ query: GET_USER, variables: { id } }],
      });
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisible}
        closable={false}
        onClose={() => {
          setDrawerVisible(false);
          setTimeout(() => history.push('/users'), 300);
        }}
      >
        {(() => {
          if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
          if (loading) return <div>Loading!</div>;
          if (!data?.users_by_pk) return <div>No data found</div>;

          // [label, name, editable]
          const userDetails: [string, string, boolean][] = [
            ['id', 'id', false],
            ['Name', 'name', true],
            ['Given name', 'given_name', true],
            ['Family name', 'family_name', true],
            ['Email', 'email', false],
            ['Mobile', 'mobile', true],
            ['Nickname', 'nickname', false],
            ['Created', 'created_at', false],
            ['Last seen', 'last_seen', false],
            ['Webhook URL', 'webhook_url', true],
          ];

          const initialValues = userDetails.reduce((acc: any, [, key]) => {
            acc[key] = user?.[key as keyof typeof User];
            return acc;
          }, {});

          const onCancel = () => setEdit(false);
          const onSubmit = (val: any) => {
            const { id: _1, created_at: _2, ...changes } = val;
            const variables = {
              id,
              changes,
            };
            updateUser({
              variables,
              refetchQueries: [{ query: GET_USER, variables: { id } }],
            })
              .then(onCancel)
              .catch(setUpdateError);
          };

          return (
            <Row gutter={[16, 16]} style={{ padding: 8, margin: 0 }}>
              <Col xs={24} md={10}>
                <Card
                  size="small"
                  title={
                    <>
                      <span style={{ lineHeight: '32px' }}>User details</span>
                      <> • </>
                      <Link
                        to={`/properties?where=${JSON.stringify({
                          agent_user_id: { _eq: user?.id },
                        })}`}
                      >
                        View leads
                      </Link>
                      {!edit && (
                        <Button
                          style={{ float: 'right' }}
                          onClick={() => setEdit(true)}
                        >
                          <EditOutlined />
                          Edit
                        </Button>
                      )}
                    </>
                  }
                  className="card-table"
                >
                  {updateError}
                  {edit ? (
                    <Form
                      onFinish={onSubmit}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={initialValues}
                      autoComplete="off"
                      style={{ margin: 10 }}
                    >
                      {userDetails
                        .filter((d) => d[2])
                        .map((d) => (
                          <Form.Item label={d[0]} name={d[1]} key={d[1]}>
                            <Input />
                          </Form.Item>
                        ))}
                      <Form.Item
                        style={{
                          textAlign: 'right',
                          margin: -10,
                          padding: 10,
                          borderTop: '1px solid #f0f0f0',
                        }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Button
                          style={{ marginRight: 10 }}
                          htmlType="button"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (
                    <table className="card-table">
                      <tbody>
                        {userDetails.map(([label, name]) => (
                          <tr key={name}>
                            <th>{label}</th>
                            <td>{user?.[name as keyof typeof User]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Card>
                <Card
                  size="small"
                  title={
                    <>
                      Zip codes
                      <> • </>
                      <Link to={`/users/${user?.id}/zips`}>Open map</Link>
                      <Link
                        style={{ float: 'right' }}
                        to={`/properties?where=${JSON.stringify({
                          sms_verified_at: { _is_null: false },
                          sale_horizon: {
                            _in: [
                              '6_to_12_months',
                              'asap',
                              '12_to_24_months',
                              'over_24_months',
                            ],
                          },
                          postal_code: {
                            _in: zipCodes?.map((z) => z.zip_code),
                          },
                        })}`}
                      >
                        View leads in area
                      </Link>
                    </>
                  }
                  style={{ marginTop: 10 }}
                >
                  <ZipCodeSelect
                    value={null}
                    style={{ width: '100%' }}
                    onSelect={onSelect}
                  />
                  {zipCodes?.map((z) => (
                    <Tag
                      style={{ marginTop: 5 }}
                      key={z.id}
                      closable
                      onClose={() => onDeselect(z)}
                    >
                      {z.zip_code}
                    </Tag>
                  ))}
                  <Divider style={{ margin: '12px 0' }} />
                  Total population:{' '}
                  <strong>
                    {zipCodes
                      ?.reduce(
                        (acc, z) => acc + (z.population ? z.population : 0),
                        0
                      )
                      .toLocaleString()}
                  </strong>
                </Card>
              </Col>
              <Col xs={24} md={14}>
                {user && <Feed user={user} />}
              </Col>
            </Row>
          );
        })()}
      </Drawer>
      <Route path="/users/:id/zips">
        {user && (
          <Zip
            onClose={() => {
              history.push(`/users/${id}`);
            }}
            onSelect={onSelect}
            onDeselect={onDeselect}
            // @ts-ignore
            initialZips={data?.users_by_pk?.zip_codes.map((z) => z.zip_code)}
          />
        )}
      </Route>
    </>
  );
};

export default User;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Properties from './Properties';
import Property from './Property/Property';
import Users from './Users';
import User from './User/User';
import Teams from './Teams';
import Team from './Team/Team';
import CreateTeam from './Team/CreateTeam';
import Settings from './Settings';
import Feed from './Feed';
import Zip from './Zip/Zip';
import StripeCustomers from './StripeCustomers/StripeCustomers';
import StripeCustomer from './StripeCustomers/StripeCustomer';
import StripeAccounts from './StripeAccounts/StripeAccounts';
import StripeAccount from './StripeAccounts/StripeAccount';

const Routes = () => {
  return (
    <Switch>
      <Route path="/properties">
        <Properties />
        <Switch>
          <Route path="/properties/:id">
            <Property />
          </Route>
        </Switch>
      </Route>
      <Route path="/users">
        <Users />
        <Switch>
          <Route path="/users/:id">
            <User />
          </Route>
        </Switch>
      </Route>
      <Route path="/stripe-accounts">
        <StripeAccounts />
        <Switch>
          <Route path="/stripe-accounts/:id">
            <StripeAccount />
          </Route>
        </Switch>
      </Route>
      <Route path="/stripe-customers">
        <StripeCustomers />
        <Switch>
          <Route path="/stripe-customers/:id">
            <StripeCustomer />
          </Route>
        </Switch>
      </Route>
      <Route path="/teams">
        <Teams />
        <Switch>
          <Route path="/teams/create">
            <CreateTeam />
          </Route>
          <Route path="/teams/:id">
            <Team />
          </Route>
        </Switch>
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
      <Route path="/feed">
        <Feed />
      </Route>
      <Route path="/">
        <Redirect to="/properties" />
      </Route>
    </Switch>
  );
};

export default Routes;

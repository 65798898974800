import { Result, Button, Row, Col } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { LockOutlined } from '@ant-design/icons';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  // Parse query parameters and look for error description
  const urlParams = new URLSearchParams(window.location.search);
  const errorDescription = urlParams.get('error_description');

  return (
    <Row align="middle" style={{ height: '100%' }}>
      <Col span={12} offset={6}>
        <Result
          icon={<LockOutlined />}
          title={errorDescription || 'Please log in'}
          subTitle="You must be logged-in to continue"
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: window.location.pathname + window.location.search,
                  },
                })
              }
            >
              Log In
            </Button>
          }
        />
      </Col>
    </Row>
  );
};

export default Login;

import { useLocation } from 'react-router-dom';

import MyTable from './Table';
import Filters from '../../components/Filters';

const Properties = () => {
  let where = new URLSearchParams(useLocation().search).get('where');
  if (where) {
    try {
      where = JSON.parse(where);
    } catch (e) {
      where = null;
    }
  }
  const page = new URLSearchParams(useLocation().search).get('page');
  const currentPage = page ? parseInt(page, 10) : 1;
  return (
    <>
      <Filters />
      <MyTable where={where} page={currentPage} />
    </>
  );
};

export default Properties;

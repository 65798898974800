import { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Row, Col, Popconfirm, Button, notification, Typography } from 'antd';
import Drawer from '../../components/Drawer';
import {
  GET_PROPERTY,
  GET_PROPERTIES,
  DELETE_PROPERTY,
} from '../../graphql/property';
import { GetPropertyQuery } from '../../generated/graphql';

import Details from './Details';
import Feed from './Feed';
import Map from './Map';

const openNotification = (message: any, description: any) => {
  notification.open({
    message,
    description,
  });
};

const Property = () => {
  const history = useHistory();
  const [drawerVisible, setDrawerVisible] = useState(true);
  const { id } = useParams<{ id: string }>();
  const where = new URLSearchParams(useLocation().search).get('where') || '{}';
  const page = new URLSearchParams(useLocation().search).get('page') || '1';
  const params: any = {};
  if (parseInt(page, 10) > 1) params.page = page;
  if (where.length > 2) params.where = where;
  const searchParams = Object.keys(params).length
    ? new URLSearchParams(params).toString()
    : undefined;

  const returnUrl = searchParams
    ? `/properties?${searchParams}`
    : '/properties';

  const onClose = () => {
    setDrawerVisible(false);
    setTimeout(() => history.push(returnUrl), 300);
  };

  const [deleteProperty] = useMutation(DELETE_PROPERTY, {
    refetchQueries: [
      { query: GET_PROPERTIES, variables: { where: JSON.parse(where) } },
    ],
  });
  const { error, data, loading } = useQuery<GetPropertyQuery>(GET_PROPERTY, {
    variables: { id },
  });

  const property = data?.properties_by_pk;

  return (
    <Drawer closable={false} onClose={onClose} visible={drawerVisible}>
      <Map loading={loading} lat={property?.lat} lng={property?.lng} />
      {(() => {
        if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
        if (loading) return <div>Loading!</div>;
        if (!property) return <div>Property not found!</div>;

        return (
          <Row gutter={[16, 16]} style={{ padding: 8, margin: 0 }}>
            <Col xs={24} md={10}>
              <Details property={property} />
              <div style={{ paddingTop: 8 }}>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() =>
                    deleteProperty({ variables: { id } }).then(() => {
                      history.replace(returnUrl);
                    })
                  }
                >
                  <Button>Delete</Button>
                </Popconfirm>
                <Button
                  disabled={typeof property.agent?.webhook_url !== 'string'}
                  onClick={() => {
                    const requestOptions = {
                      method: 'POST',
                      body: JSON.stringify({
                        lead: property,
                      }),
                    };
                    if (typeof property.agent?.webhook_url !== 'string') return;
                    fetch(property.agent.webhook_url, requestOptions)
                      .then((response) => response.json())
                      .then((res) =>
                        openNotification(
                          <Typography.Text strong>Response</Typography.Text>,
                          <pre>{JSON.stringify(res, null, 2)}</pre>
                        )
                      )
                      .catch((err) =>
                        openNotification(
                          <Typography.Text type="danger" strong>
                            An error occurred
                          </Typography.Text>,
                          <Typography.Text type="danger">
                            {err.toString()}
                          </Typography.Text>
                        )
                      );
                  }}
                >
                  Call Webhook
                </Button>
                {process.env.NODE_ENV === 'development' && (
                  <Button
                    onClick={() =>
                      window.open(
                        `http://localhost:3000/estimate/${property.id}/result`,
                        '_blank'
                      )
                    }
                  >
                    Open results page
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={24} md={14}>
              <Feed property={property} />
            </Col>
          </Row>
        );
      })()}
    </Drawer>
  );
};

export default Property;

import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { format, TDate } from 'timeago.js';
import { Table, Tooltip } from 'antd';

import { GET_PROPERTIES } from '../../graphql/property';
import { GetPropertiesQuery } from '../../generated/graphql';

type Property = GetPropertiesQuery['properties'][0];

const columns: any = [
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created',
    width: 100,
    ellipsis: true,
    render: (t: TDate) =>
      t ? (
        <Tooltip title={new Date(t).toLocaleDateString()}>
          {format(t, 'en_US')}
        </Tooltip>
      ) : (
        ''
      ),
  },
  {
    title: 'Property',
    dataIndex: 'property_type',
    key: 'property_type',
    ellipsis: true,
    render: (_: string, record: Property) =>
      [record.property_type, record.occupation_type]
        .filter((v) => v)
        .join(' → '),
  },
  {
    title: 'Address',
    key: 'address',
    width: 350,
    ellipsis: true,
    render: (_: string, record: Property) =>
      [
        [record.street_number, record.route].filter((v) => v).join(' '),
        record.locality,
        [record.state, record.postal_code].filter((v) => v).join(' '),
      ]
        .filter((v) => v)
        .join(', '),
  },
  {
    title: 'Name',
    key: 'name',
    width: 200,
    ellipsis: true,
    render: (_: string, record: Property) =>
      [
        record.sms_verified_at ? '🟢 ' : record.sms_sent_at ? '🔴 ' : '⚪️ ',
        record.given_name,
        record.family_name,
      ].join(' '),
  },
  {
    title: 'Reason',
    dataIndex: 'appraisal_reason',
    key: 'appraisal_reason',
    ellipsis: true,
    render: (_: string, record: Property) =>
      [record.user_relation, record.appraisal_reason, record.sale_horizon]
        .filter((v) => v)
        .join(' → '),
  },
  /*
  {
    title: 'Landing page',
    dataIndex: 'landing_page_url',
    key: 'landing_page_url',
    width: 500,
    ellipsis: true,
  },
  {
    title: 'Referrer',
    dataIndex: 'referrer_url',
    key: 'referrer_url',
    width: 300,
    ellipsis: true,
  },
  */
  {
    title: 'Agent',
    key: 'agent',
    width: 200,
    ellipsis: true,
    render: (_: string, record: Property) => record.agent?.name,
  },
  {
    title: 'Estimate',
    key: 'estimate',
    width: 100,
    align: 'right',
    ellipsis: true,
    render: (_: string, record: Property) =>
      `$${Intl.NumberFormat('en-US').format(record.value_estimate)}`,
  },
];

type Params = {
  page?: string;
  where?: string;
};

interface MyTableProps {
  where: any;
  page: number;
}

const MyTable = ({ where, page = 1 }: MyTableProps) => {
  const history = useHistory();
  const offset = page ? (page - 1) * 100 : null;
  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: { where, offset },
  });

  // Open in new tab if CMD is pressed
  const [cmd, setCmd] = useState(false);
  const downHandler = (e: KeyboardEvent) => setCmd(e.metaKey);
  const upHandler = () => setCmd(false);
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
  const onClick = (r: Property) => {
    if (cmd)
      return window.open(
        `${window.location.origin}/properties/${r.id}`,
        '_blank'
      );
    const params: Params = {};
    if (page > 1) params.page = page.toString();
    if (where) params.where = JSON.stringify(where);
    const searchParams = new URLSearchParams(params).toString();
    return history.push(
      `/properties/${r.id}${searchParams ? `?${searchParams}` : ''}`
    );
  };

  if (loading)
    return (
      <Table
        style={{ cursor: 'pointer', minWidth: 800 }}
        onRow={(r) => ({
          onClick: () => onClick(r),
        })}
        rowKey="id"
        columns={columns}
        dataSource={undefined}
        size="small"
        bordered
        loading
        pagination={{
          pageSize: 100,
        }}
        title={() => 'Loading results'}
      />
    );
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <div style={{ overflowX: 'scroll', backgroundColor: 'white' }}>
      <Table
        style={{ cursor: 'pointer', minWidth: 800 }}
        onRow={(r) => ({
          onClick: () => onClick(r),
        })}
        rowKey="id"
        columns={columns}
        dataSource={data.properties}
        size="small"
        bordered
        pagination={{
          current: page,
          pageSize: 100,
          total: data.properties_aggregate.aggregate.count + offset,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) =>
            `${range[0].toLocaleString()}-${range[1].toLocaleString()} of ${total.toLocaleString()} properties`,
          onChange: (newPage) => {
            const params: Params = { page: newPage.toString() };
            if (where) params.where = JSON.stringify(where);
            const searchParams = new URLSearchParams(params).toString();
            history.push(`/properties?${searchParams}`);
          },
        }}
      />
    </div>
  );
};

export default MyTable;

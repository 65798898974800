import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Modal, Input, Form } from 'antd';

import { GET_TEAMS, INSERT_TEAM } from '../../graphql/team';

const CreateTeam = () => {
  const history = useHistory();
  const handleCancel = () => history.push(`/teams`);
  const [insertTeam] = useMutation(INSERT_TEAM);

  const onSubmit = (v: any) =>
    insertTeam({
      variables: v,
      refetchQueries: [{ query: GET_TEAMS }],
    }).then((r) => history.push(`/teams/${r.data.insert_teams_one.id}`));

  return (
    <Modal
      title="Create new team"
      visible
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        onFinish={onSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        style={{ margin: -10 }}
      >
        <Form.Item label="Team name" name="name">
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          style={{
            textAlign: 'right',
            margin: -10,
            padding: 10,
            borderTop: '1px solid #f0f0f0',
          }}
          wrapperCol={{ span: 24 }}
        >
          <Button
            style={{ marginRight: 10 }}
            htmlType="button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTeam;

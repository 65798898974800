import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
  Table,
  Typography,
  Popconfirm,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Drawer from '../../components/Drawer';
import UserSelect from '../../components/UserSelect';

import {
  GET_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  INSERT_USERS_TEAMS,
  DELETE_USERS_TEAMS,
  GET_TEAMS,
} from '../../graphql/team';
import { GetTeamQuery, UpdateTeamMutation } from '../../generated/graphql';
type Team = NonNullable<GetTeamQuery['teams_by_pk']>;

const Team = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateTeam] = useMutation<UpdateTeamMutation>(UPDATE_TEAM);
  const [deleteTeam] = useMutation(DELETE_TEAM);
  const [insertUsersTeams] = useMutation(INSERT_USERS_TEAMS);
  const [deleteUsersTeams] = useMutation(DELETE_USERS_TEAMS);

  const { error, data, loading } = useQuery<GetTeamQuery>(GET_TEAM, {
    variables: { id },
  });

  const columns = [
    {
      title: 'Added',
      dataIndex: 'added_at',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_: any, record: Team) => (
        <Button
          type="link"
          danger
          onClick={() => {
            deleteUsersTeams({
              variables: { user_id: record.id, team_id: id },
              refetchQueries: [{ query: GET_TEAM, variables: { id } }],
            });
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Drawer closable={false} onClose={() => history.push('/teams')}>
      {(() => {
        if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
        if (loading) return <div>Loading!</div>;
        if (!data?.teams_by_pk) return <div>No data found</div>;

        const team = data.teams_by_pk;

        const users: any = data.teams_by_pk.users_teams.map((u) => ({
          ...u.user,
          added_at: u.created_at,
        }));

        // [label, name, editable]
        const teamDetails: [string, string, boolean][] = [
          ['id', 'id', false],
          ['Name', 'name', true],
          ['Created', 'created_at', false],
        ];

        const initialValues: any = teamDetails.reduce((acc: any, [, key]) => {
          acc[key] = team[key as keyof typeof Team];
          return acc;
        }, {});

        const onCancel = () => setEdit(false);
        const onSubmit = (val: any) => {
          const { id: _1, created_at: _2, ...changes } = val;
          const variables = {
            id,
            changes,
          };
          updateTeam({
            variables,
            refetchQueries: [{ query: GET_TEAM, variables: { id } }],
          })
            .then(onCancel)
            .catch(setUpdateError);
        };

        return (
          <>
            <Row gutter={[16, 16]} style={{ padding: 8, margin: 0 }}>
              <Col xs={24} md={10}>
                <Card
                  size="small"
                  title={
                    <>
                      <span style={{ lineHeight: '32px' }}>Team details</span>
                      {!edit && (
                        <Button
                          style={{ float: 'right' }}
                          onClick={() => setEdit(true)}
                        >
                          <EditOutlined />
                          Edit
                        </Button>
                      )}
                    </>
                  }
                  className="card-table"
                >
                  {updateError}
                  {edit ? (
                    <Form
                      onFinish={onSubmit}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValues={initialValues}
                      autoComplete="off"
                      style={{ margin: 10 }}
                    >
                      {teamDetails
                        .filter((d) => d[2])
                        .map(([label, name]) => (
                          <Form.Item label={label} name={name} key={name}>
                            <Input />
                          </Form.Item>
                        ))}
                      <Form.Item
                        style={{
                          textAlign: 'right',
                          margin: -10,
                          padding: 10,
                          borderTop: '1px solid #f0f0f0',
                        }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Popconfirm
                          placement="topLeft"
                          title="Are you sure?"
                          onConfirm={() =>
                            deleteTeam({
                              variables: { id },
                              refetchQueries: [{ query: GET_TEAMS }],
                            }).then(() => history.push('/teams'))
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            style={{ float: 'left' }}
                            htmlType="button"
                            type="link"
                            danger
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                        <Button
                          style={{ marginRight: 10 }}
                          htmlType="button"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (
                    <table className="card-table">
                      <tbody>
                        {teamDetails.map(([label, name]) => (
                          <tr key={name}>
                            <th>{label}</th>
                            <td>{team[name as keyof typeof Team]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Card>
              </Col>
            </Row>
            <div style={{ padding: 16, width: '100%' }}>
              <Typography.Title level={4}>Add User</Typography.Title>
              <UserSelect
                defaultValue={{}}
                onChange={(v: any) =>
                  v
                    ? insertUsersTeams({
                        variables: { user_id: v.value, team_id: team.id },
                        refetchQueries: [
                          { query: GET_TEAM, variables: { id } },
                        ],
                      }).catch()
                    : null
                }
              />
              <Table columns={columns} dataSource={users} rowKey="id" />
            </div>
          </>
        );
      })()}
    </Drawer>
  );
};

export default Team;

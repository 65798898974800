import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { GET_STRIPE_ACCOUNTS } from '../../graphql/stripeAccount';
import { GetStripeAccountsQuery } from '../../generated/graphql';
type StripeAccount = GetStripeAccountsQuery['stripe_accounts'][0];

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Active Subscriptions',
    dataIndex: ['subscriptions_aggregate', 'aggregate', 'count'],
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Invoiced',
    dataIndex: ['invoices_aggregate', 'aggregate', 'sum', 'amount_paid'],
    width: 150,
    ellipsis: true,
    render: (t: number) => (t ? (t / 100).toLocaleString() : '-'),
  },
  {
    title: 'Charged',
    dataIndex: ['charges_aggregate', 'aggregate', 'sum', 'amount_captured'],
    width: 150,
    ellipsis: true,
    render: (t: number) => (t ? (t / 100).toLocaleString() : '-'),
  },
];

const StripeAccounts = () => {
  const history = useHistory();
  const { error, data, loading } =
    useQuery<GetStripeAccountsQuery>(GET_STRIPE_ACCOUNTS);
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.stripe_accounts}
        rowKey="id"
        onRow={(r) => ({
          onClick: () => history.push(`/stripe-accounts/${r.id}`),
        })}
      />
    </div>
  );
};

export default StripeAccounts;

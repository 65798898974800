import { Tag, Divider, Typography, Timeline, Collapse } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { format } from 'timeago.js';

import ActivityCard from '../../components/ActivityCard';
import { GET_USER } from '../../graphql/user';
import { GetUserQuery } from '../../generated/graphql';
type User = NonNullable<GetUserQuery['users_by_pk']>;

const { Text } = Typography;
const { Panel } = Collapse;

interface FeedProps {
  user: User;
}

const Feed = ({ user: u }: FeedProps) => {
  return (
    <>
      <Timeline>
        {(u.activities || []).map((a) => (
          <ActivityCard activity={a} key={a.id} />
        ))}
        <Divider orientation="center">
          <Tag>Past</Tag>
        </Divider>
        <Timeline.Item
          style={{ paddingBottom: 8 }}
          dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
        >
          <Collapse>
            <Panel
              header={
                <>
                  User created
                  <Text type="secondary" style={{ float: 'right' }}>
                    {format(u.created_at)}
                  </Text>
                </>
              }
              key="1"
            >
              <pre>{JSON.stringify(u, null, 2)}</pre>
            </Panel>
          </Collapse>
        </Timeline.Item>
      </Timeline>
    </>
  );
};

export default Feed;

import { useState, useEffect } from 'react';
import { message, Input } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

const { Search } = Input;

interface MySearchProps {
  style?: React.CSSProperties;
}

const MySearch = (props: MySearchProps) => {
  const history = useHistory();
  const location = useLocation();
  const [inputValue, setInputValue] = useState('');

  const w = new URLSearchParams(location.search).get('where');

  useEffect(() => {
    if (w) {
      try {
        setInputValue(JSON.stringify(JSON.parse(w), null, 1));
      } catch (e) {
        message.error('Search bar: Invalid query in URL');
      }
    } else {
      setInputValue('');
    }
  }, [w]);

  return (
    <Search
      placeholder="Filter results..."
      allowClear
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onSearch={(s) => {
        if (!s) return history.push('/properties');
        try {
          const w = JSON.parse(s);
          return history.push(`/properties?where=${JSON.stringify(w)}`);
        } catch (e) {
          message.error('Invalid JSON in search bar!');
        }
        return null;
      }}
      style={{ width: '100%' }}
    />
  );
};

export default MySearch;

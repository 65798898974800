// This is a individual filter component
// Used by the Filters component

import { useState, useEffect } from 'react';
import { message, Button, Select, Input } from 'antd';
import Filters from './Filters';

interface FilterProps {
  fields: { name: string; type: string }[];
  operators: string[];
  field?: string;
  operator?: string;
  value?: string | string[];
  onChange: ({
    field,
    operator,
    value,
  }: {
    field?: string;
    operator?: string;
    value?: string | string[];
  }) => void;
}

const onSearch = (value: string) => {
  console.log('search:', value);
};

const { Option } = Select;

const Filter = ({
  fields,
  operators,
  field: defaultField,
  operator: defaultOperator,
  value: defaultValue,
  onChange,
}: FilterProps) => {
  const [field, setField] = useState(defaultField);
  const [operator, setOperator] = useState(defaultOperator);
  const [value, setValue] = useState(defaultValue);

  return (
    <div style={{ padding: '0 10px 10px 10px', backgroundColor: 'white' }}>
      {/* <pre>{JSON.stringify({ field, operator, value }, null, 0)}</pre> */}
      <Select
        style={{ width: 200, marginRight: 5 }}
        showSearch
        placeholder="Select a field..."
        optionFilterProp="children"
        value={field}
        onChange={(v) => setField(v)}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {fields?.map((f) => (
          <Option key={f?.name} value={f?.name}>
            {f?.name}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Select an operator"
        style={{ width: 100, marginRight: 5 }}
        value={operator}
        onChange={(v) => setOperator(v)}
        disabled={!field}
      >
        {operators.map((o) => (
          <Option key={o} value={o}>
            {o}
          </Option>
        ))}
      </Select>
      {operator !== '_is_null' && (
        <Input
          placeholder="Enter a value"
          style={{ width: 200, marginRight: 5 }}
          value={value}
          onChange={(e) => {
            const newValue = ['_in', '_nin'].includes(operator || '')
              ? e.target.value.split(',')
              : e.target.value;
            setValue(newValue);
          }}
          disabled={!operator}
        />
      )}
      {operator === '_is_null' && (
        <Select
          placeholder="Select a value"
          style={{ width: 200, marginRight: 5 }}
          value={value}
          onChange={(v) => setValue(v)}
          disabled={!operator}
        >
          <Option key="true" value={true}>
            true
          </Option>
          <Option key="false" value={false}>
            false
          </Option>
        </Select>
      )}
      {!defaultField && (
        <Button
          type="primary"
          style={{ width: 100, marginRight: 5 }}
          disabled={!field || !operator || typeof value === 'undefined'}
          onClick={() => {
            setField(undefined);
            setOperator(undefined);
            setValue(undefined);
            onChange({ field, operator, value });
          }}
        >
          Add
        </Button>
      )}
      {defaultField && (
        <Button
          type="default"
          style={{ width: 100, marginRight: 5 }}
          disabled={
            field === defaultField &&
            operator === defaultOperator &&
            value === defaultValue
          }
          onClick={() => {
            onChange({ field, operator, value });
          }}
        >
          Apply
        </Button>
      )}
      {defaultField && (
        <Button
          type="default"
          style={{ width: 100, marginRight: 5 }}
          onClick={() => {
            onChange({ field, operator: undefined, value: undefined });
          }}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default Filter;

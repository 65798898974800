import { useQuery } from '@apollo/client';
import { Timeline, Col, Row, Typography } from 'antd';
import { GET_ACTIVITIES } from '../graphql/activity';
import ActivityCard from '../components/ActivityCard';
import { GetActivitiesQuery } from '../generated/graphql';

const Feed = () => {
  const { loading, error, data } = useQuery<GetActivitiesQuery>(GET_ACTIVITIES);
  const { Title } = Typography;

  if (loading) return <p>Loading...</p>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <Row gutter={[16, 16]} style={{ padding: 8, margin: 10 }}>
      <Col xs={24} md={12}>
        <Title level={2}>Recent Activities</Title>
        <Timeline>
          {data?.activities.map((a) => (
            <ActivityCard activity={a} key={a.id} />
          ))}
        </Timeline>
      </Col>
    </Row>
  );
};

export default Feed;

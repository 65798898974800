import { gql } from '@apollo/client';

export const GET_ZIP_CODES = gql`
  query GetZipCodes($_ilike: String = "%", $limit: Int = 10) {
    zip_codes(
      where: {
        _or: [
          { zip_code: { _ilike: $_ilike } }
          { po_name: { _ilike: $_ilike } }
        ]
      }
      limit: $limit
      order_by: { zip_code: asc }
    ) {
      id
      population
      pop_sqmi
      po_name
      sqmi
      state
      shape__are
      shape__len
      zip_code
      centroid
    }
  }
`;

export const ADD_ZIP_CODE = gql`
  mutation AddZipCode($user_id: String!, $zip_code_id: String!) {
    insert_users_zip_codes_one(
      object: { user_id: $user_id, zip_code_id: $zip_code_id }
    ) {
      zip_code_id
    }
  }
`;

export const DELETE_ZIP_CODE = gql`
  mutation DeleteZipCode($user_id: String!, $zip_code_id: String!) {
    delete_users_zip_codes_by_pk(user_id: $user_id, zip_code_id: $zip_code_id) {
      user_id
      zip_code_id
    }
  }
`;

export const GET_AGENTS_MAP = gql`
  query GetAgentsMap {
    zip_codes(
      where: {
        users_zip_codes: {
          user_id: { _is_null: false }
          user: { customers: { subscriptions: { status: { _eq: "active" } } } }
        }
      }
    ) {
      id
      zip_code
      users_zip_codes_aggregate {
        aggregate {
          count
        }
        nodes {
          user {
            name
          }
        }
      }
    }
  }
`;

import { useState } from 'react';
import { useParams, useHistory, Link, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_STRIPE_CUSTOMER } from '../../graphql/stripeCustomer';
import { UPDATE_STRIPE_CUSTOMER } from '../../graphql/stripeCustomer';
import {
  GetStripeCustomerQuery,
  UpdateStripeCustomerMutation,
} from '../../generated/graphql';

import UserSelect from '../../components/UserSelect';
import Drawer from '../../components/Drawer';
import { Space } from 'antd';

const StripeCustomer = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [drawerVisible, setDrawerVisible] = useState(true);

  const { error, data, loading } = useQuery<GetStripeCustomerQuery>(
    GET_STRIPE_CUSTOMER,
    {
      variables: { id },
    }
  );
  const [updateCustomer] = useMutation<UpdateStripeCustomerMutation>(
    UPDATE_STRIPE_CUSTOMER
  );

  return (
    <>
      <Drawer
        visible={drawerVisible}
        closable={false}
        onClose={() => {
          setDrawerVisible(false);
          setTimeout(() => history.push('/stripe-customers'), 300);
        }}
      >
        {(() => {
          if (loading) return <div>Loading...</div>;
          if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
          if (!data) return <div>Not found</div>;

          const cus = data.stripe_customers_by_pk;

          return (
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex', padding: 20 }}
            >
              <h1>STRIPE CUSTOMER: {cus?.name}</h1>
              <p>
                <strong>Stripe Customer email:</strong> {cus?.email}
              </p>
              <label>Matching CRM User Account</label>
              <UserSelect
                onChange={(v: any) => {
                  updateCustomer({
                    variables: {
                      id: cus?.id,
                      user_id: v?.value || null,
                    },
                  });
                }}
                defaultValue={cus?.user_id}
              />
            </Space>
          );
        })()}
      </Drawer>
    </>
  );
};

export default StripeCustomer;

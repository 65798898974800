import React from 'react';
import { Tag, Divider, Typography, Timeline, Collapse } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { format } from 'timeago.js';

import ActivityCard from '../../components/ActivityCard';
import ActivityForm from '../../components/ActivityForm';
import { GetPropertyQuery } from '../../generated/graphql';
import Property from './Property';

type Property = NonNullable<GetPropertyQuery['properties_by_pk']>;

const { Text } = Typography;
const { Panel } = Collapse;

interface FeedProps {
  property: Property;
}

const Feed = ({ property: p }: FeedProps) => {
  return (
    <>
      <Timeline>
        <ActivityForm propertyId={p.id} />
        {p.activities.map((a) => (
          <ActivityCard activity={a} key={a.id} />
        ))}
        <Divider orientation="center">
          <Tag>Past</Tag>
        </Divider>
        <Timeline.Item
          style={{ paddingBottom: 8 }}
          dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
        >
          <Collapse>
            <Panel
              header={
                <>
                  Lead created
                  <Text type="secondary" style={{ float: 'right' }}>
                    {format(p.created_at)}
                  </Text>
                </>
              }
              key="1"
            >
              <pre>{JSON.stringify(p, null, 2)}</pre>
            </Panel>
          </Collapse>
        </Timeline.Item>
      </Timeline>
    </>
  );
};

export default Feed;

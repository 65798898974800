import { useState } from 'react';
import {
  Popconfirm,
  Radio,
  DatePicker,
  Space,
  Form,
  Input,
  Button,
  Card,
  Timeline,
} from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';

import { GET_PROPERTY } from '../graphql/property';
import {
  INSERT_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
} from '../graphql/activity';
import { GET_ACTIVITY_TYPES } from '../graphql/activityType';
import {
  GetActivitiesQuery,
  GetActivityTypesQuery,
  GetPropertyQuery,
  InsertActivityMutation,
  UpdateActivityMutation,
  DeleteActivityMutation,
  InsertActivityMutationVariables,
  UpdateActivityMutationVariables,
} from '../generated/graphql';

type Activity =
  | GetActivitiesQuery['activities'][0]
  | NonNullable<GetPropertyQuery['properties_by_pk']>['activities'][0];

const { TextArea } = Input;

interface ActivityFormProps {
  activity?: Activity;
  propertyId: string;
  afterSuccess?: () => void;
}

const ActivityForm = ({
  activity,
  propertyId,
  afterSuccess = () => null,
}: ActivityFormProps) => {
  const [form] = Form.useForm();

  const { data } = useQuery<GetActivityTypesQuery>(GET_ACTIVITY_TYPES);
  const [insertActivity] = useMutation<InsertActivityMutation>(INSERT_ACTIVITY);
  const [updateActivity] = useMutation<UpdateActivityMutation>(UPDATE_ACTIVITY);
  const [deleteActivity] = useMutation<DeleteActivityMutation>(DELETE_ACTIVITY);

  const [activityType, setActivityType] = useState(activity?.activity_type);
  const [error, setError] = useState(null);

  const initialValues = {
    ...activity,
    due_at: activity ? moment(activity.due_at) : null,
  };

  const onCancel = () => {
    form.resetFields();
    setActivityType(undefined);
    afterSuccess();
  };

  const onSubmit = activity
    ? (values: any) => {
        updateActivity({
          variables: {
            id: activity.id,
            changes: values,
          } as UpdateActivityMutationVariables,
          refetchQueries: [
            { query: GET_PROPERTY, variables: { id: propertyId } },
          ],
        })
          .then(onCancel)
          .catch(setError);
      }
    : (values: any) => {
        insertActivity({
          variables: {
            property_id: propertyId,
            object: {
              property_id: propertyId,
              ...values,
            },
          } as InsertActivityMutationVariables,
          refetchQueries: [
            { query: GET_PROPERTY, variables: { id: propertyId } },
          ],
        })
          .then(onCancel)
          .catch(setError);
      };

  const handleFormValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === 'activity_type_id') {
      form.setFieldsValue({ due_at: moment() });
      setActivityType(
        data?.activity_types.filter((t) => t.id === changedValues[fieldName])[0]
      );
    }
  };

  return (
    <>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <Form
        name="note"
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        onValuesChange={handleFormValuesChange}
        initialValues={initialValues}
      >
        {!activity?.id && (
          <Form.Item
            name="activity_type_id"
            rules={[
              {
                required: true,
                message: 'Please pick an item!',
              },
            ]}
          >
            <Radio.Group>
              <Space style={{ margin: '0 25px' }}>
                {data &&
                  data.activity_types.map((t) => (
                    <Radio.Button value={t.id} key={t.id}>
                      <span style={{ margin: '0 7px 0 -5px' }}>{t.emoji}</span>
                      {t.label}
                    </Radio.Button>
                  ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        {activityType && (
          <Timeline.Item dot={activityType.emoji}>
            <Card
              size="small"
              title={`${activity ? '✏️  Edit' : '⭐️  New'} ${
                activityType.label
              }`}
            >
              {activityType.id === 'task' && (
                <Form.Item
                  name="due_at"
                  label="Due date"
                  rules={[{ required: true, message: 'Please chose a date' }]}
                >
                  <DatePicker />
                </Form.Item>
              )}
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: 'Please write a description!' },
                ]}
              >
                <TextArea autoSize={{ minRows: 3 }} size="large" autoFocus />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                {activity && (
                  <Popconfirm
                    title="Sure to delete?"
                    placement="topRight"
                    onConfirm={() =>
                      deleteActivity({
                        variables: { id: activity.id },
                        refetchQueries: [
                          {
                            query: GET_PROPERTY,
                            variables: { id: propertyId },
                          },
                        ],
                      })
                    }
                  >
                    <Button danger htmlType="button" style={{ float: 'right' }}>
                      Delete
                    </Button>
                  </Popconfirm>
                )}
                <Button
                  htmlType="button"
                  style={{ marginRight: 10 }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Card>
          </Timeline.Item>
        )}
      </Form>
    </>
  );
};

export default ActivityForm;

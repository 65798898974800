import React, { useState } from 'react';
import { Drawer, Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TeamOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SideMenuProps {
  isMobile: boolean;
  open: boolean;
  setOpen: (x: boolean) => void;
}

const SideMenu = ({ isMobile, open, setOpen }: SideMenuProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const { user } = useAuth0();

  const MenuItems = [
    <Menu.Item key="properties" icon={<HomeOutlined />}>
      <Link to="/properties">Properties</Link>
    </Menu.Item>,
    <Menu.Item key="users" icon={<UserOutlined />}>
      <Link to="/users">Users</Link>
    </Menu.Item>,
    user?.['https://hasura.io/jwt/claims']['x-hasura-role'] === 'admin' ? (
      <Menu.Item key="teams" icon={<TeamOutlined />}>
        <Link to="/teams">Teams</Link>
      </Menu.Item>
    ) : null,
    <SubMenu key="sub1" icon={<SettingOutlined />} title="Settings">
      <Menu.Item key="profile">
        <Link to={`/users/${user?.email}`}>Profile</Link>
      </Menu.Item>
      <Menu.Item key="dev">
        <Link to="/settings/profile">Dev</Link>
      </Menu.Item>
      <Menu.Item key="activity-types">
        <Link to="/settings/activities">Activity types</Link>
      </Menu.Item>
      <Menu.Item key="stripe-accounts">
        <Link to="/stripe-accounts">Stripe Accounts</Link>
      </Menu.Item>
      <Menu.Item key="stripe-customers">
        <Link to="/stripe-customers">Stripe Customers</Link>
      </Menu.Item>
      <Menu.Item key="agents-maps">
        <Link to="/settings/agents-map">Agents Map</Link>
      </Menu.Item>
    </SubMenu>,
  ];

  return isMobile ? (
    <Drawer
      placement="left"
      closable={false}
      bodyStyle={{ padding: 0 }}
      visible={open}
      onClose={() => setOpen(false)}
    >
      <Menu
        mode="inline"
        theme="dark"
        inlineIndent={16}
        style={{ height: '100vh' }}
      >
        {MenuItems}
      </Menu>
    </Drawer>
  ) : (
    <Sider
      breakpoint="lg"
      collapsed={collapsed}
      // onBreakpoint={(broken) => setCollapsed(broken)}
      collapsedWidth="48"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <Menu
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        //collapsedWidth={48}
        inlineIndent={16}
      >
        {MenuItems}
        <Menu.Item
          key="0"
          onClick={() => setCollapsed(!collapsed)}
          icon={React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        >
          {collapsed ? 'Expand' : 'Collapse'}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideMenu;

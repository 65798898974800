import React from 'react';
import { Card, Collapse } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user } = useAuth0();
  return (
    <div style={{ padding: '16px' }}>
      <Collapse>
        <Collapse.Panel header="User data" key="1">
          <Card>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </Card>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default Profile;

import { useQuery } from '@apollo/client';
import { Badge, Table, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { format, TDate } from 'timeago.js';
import { GET_USERS } from '../graphql/user';
import { GetUsersQuery } from '../generated/graphql';
type User = GetUsersQuery['users'][0];

const columns = [
  {
    title: 'Created',
    dataIndex: 'created_at',
    key: 'created',
    width: 100,
    ellipsis: true,
    render: (t: TDate) => (
      <Tooltip title={new Date(t).toLocaleDateString()}>
        {format(t, 'en_US')}
      </Tooltip>
    ),
  },
  {
    title: 'Last seen',
    dataIndex: 'last_seen',
    key: 'last_seen',
    width: 100,
    ellipsis: true,
    render: (t: TDate) =>
      t ? (
        <Tooltip title={new Date(t).toLocaleDateString()}>
          {format(t, 'en_US')}
        </Tooltip>
      ) : (
        ''
      ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
    ellipsis: true,
    render: (t: string, u: User) => (
      <>
        <Badge
          status={
            u.customers.filter(
              (c) => c.subscriptions_aggregate.aggregate?.count
            ).length
              ? 'success'
              : 'error'
          }
        />
        {t}
      </>
    ),
  },
  {
    title: 'Given Name',
    dataIndex: 'given_name',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Family Name',
    dataIndex: 'family_name',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 170,
    ellipsis: true,
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    width: 110,
    ellipsis: true,
  },
  {
    title: 'Last lead',
    dataIndex: ['properties_aggregate', 'aggregate', 'max', 'created_at'],
    width: 100,
    align: 'end' as any,
    render: (t: TDate) => (
      <Tooltip title={new Date(t).toLocaleDateString()}>
        {t ? format(t, 'en_US') : '-'}
      </Tooltip>
    ),
  },
  {
    title: 'Leads',
    dataIndex: ['properties_aggregate', 'aggregate', 'count'],
    width: 75,
    align: 'end' as any,
    render: (t: number) => (t ? t : '-'),
  },
  {
    title: 'Zips',
    dataIndex: ['zip_codes_aggregate', 'aggregate', 'count'],
    width: 75,
    align: 'end' as any,
    render: (t: number) => (t ? t : '-'),
  },
  {
    title: 'Pop',
    dataIndex: ['zip_codes_aggregate', 'nodes'],
    width: 100,
    align: 'end' as any,
    render: (t: any[]) =>
      t?.length
        ? t.reduce((a, b) => a + b.zip_code.population, 0).toLocaleString()
        : '-',
  },
  {
    title: 'Area',
    dataIndex: ['zip_codes_aggregate', 'nodes'],
    width: 100,
    align: 'end' as any,
    render: (t: any[]) =>
      t?.length
        ? Math.round(
            t.reduce((a, b) => a + b.zip_code.sqmi, 0)
          ).toLocaleString()
        : '-',
  },
];

function Users() {
  const { loading, error, data } = useQuery<GetUsersQuery>(GET_USERS);
  const history = useHistory();

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const onClick = (r: User) => history.push(`/users/${r.id}`);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table
        style={{ cursor: 'pointer', minWidth: 800 }}
        scroll={{ y: 'calc(100vh - 150px)', x: '550px' }}
        columns={columns}
        dataSource={data?.users}
        loading={loading}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={(r) => ({
          onClick: () => onClick(r),
        })}
      />
    </div>
  );
}

export default Users;

import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Login from '../components/Login';
import Unauthorized from '../components/Unauthorized';
import MyHeader from '../components/MyHeader';
import SideMenu from '../components/SideMenu';

const { Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
}

const MyLayout = ({ children }: LayoutProps) => {
  const { isAuthenticated, user } = useAuth0();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [open, setOpen] = useState(false);

  if (!isAuthenticated) return <Login />;

  if (
    !['agent', 'admin'].some((r) =>
      user?.['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].includes(
        r
      )
    )
  )
    return <Unauthorized />;

  return (
    <Layout>
      <SideMenu isMobile={isMobile} open={open} setOpen={setOpen} />
      <Layout>
        <MyHeader isMobile={isMobile} setOpen={setOpen} />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default MyLayout;

import { useState } from 'react';
import { format } from 'timeago.js';
import { Button, Checkbox, Card, Typography, Timeline } from 'antd';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

import { UPDATE_ACTIVITY } from '../graphql/activity';
import {
  GetActivitiesQuery,
  GetPropertyQuery,
  UpdateActivityMutation,
} from '../generated/graphql';
import ActivityForm from './ActivityForm';

type Activity =
  | GetActivitiesQuery['activities'][0]
  | NonNullable<GetPropertyQuery['properties_by_pk']>['activities'][0];

interface ActivityCardProps {
  activity: Activity;
}

const { Text } = Typography;

const ActivityCard = ({ activity: a }: ActivityCardProps) => {
  const [updateActivity] = useMutation<UpdateActivityMutation>(UPDATE_ACTIVITY);
  const [edit, setEdit] = useState(false);

  if (edit)
    return (
      <ActivityForm
        activity={a}
        propertyId={a.property_id}
        afterSuccess={() => setEdit(false)}
      />
    );
  return (
    <>
      <Timeline.Item
        style={{ paddingBottom: 8 }}
        dot={a.activity_type.emoji}
        key={a.id}
      >
        <>
          <Card
            size="small"
            title={
              <span>
                <Text type="secondary" style={{ fontSize: 12, float: 'right' }}>
                  {[a.created_by_user.name, format(a.created_at)]
                    .filter((v) => v)
                    .join(', ')}
                  &nbsp;•
                  <Button
                    size="small"
                    type="link"
                    onClick={() => setEdit(true)}
                  >
                    edit
                  </Button>
                </Text>
                {a.activity_type.label}
              </span>
            }
          >
            {a.due_at ? (
              <>
                <Checkbox
                  checked={a.done_at}
                  onChange={(c) => {
                    updateActivity({
                      variables: {
                        id: a.id,
                        changes: {
                          done_at: c.target.checked
                            ? new Date().toISOString()
                            : null,
                        },
                      },
                    });
                  }}
                >
                  {a.description}
                </Checkbox>
                <Text
                  type="secondary"
                  style={{ display: 'block', marginLeft: 25 }}
                >
                  Due {format(a.due_at)}
                </Text>
              </>
            ) : (
              <Text style={{ whiteSpace: 'pre-line' }}>{a.description}</Text>
            )}
            {a.property && (
              <Link to={`/properties/${a.property.id}`}>
                <Text
                  type="secondary"
                  style={{
                    display: 'block',
                    margin: '5px 0 -5px 0',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <LinkOutlined style={{ marginRight: 5 }} />
                  {a.property.given_name} {a.property.family_name},
                  {a.property.formatted_address}
                </Text>
              </Link>
            )}
          </Card>
        </>
      </Timeline.Item>
    </>
  );
};

export default ActivityCard;

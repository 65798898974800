import { gql } from '@apollo/client';

export const STRIPE_CUSTOMER_FRAGMENT = gql`
  fragment StripeCustomerFragment on stripe_customers {
    id
    created
    name
    email
    subscriptions_aggregate(where: { status: { _eq: "active" } }) {
      aggregate {
        count
        max {
          current_period_end
        }
      }
    }
    account {
      name
    }
    invoices_aggregate {
      aggregate {
        sum {
          amount_paid
        }
      }
    }
    user_id
    user {
      name
    }
  }
`;

export const GET_STRIPE_CUSTOMERS = gql`
  ${STRIPE_CUSTOMER_FRAGMENT}
  query GetStripeCustomers {
    stripe_customers(order_by: { created: desc }) {
      ...StripeCustomerFragment
    }
  }
`;

export const GET_STRIPE_CUSTOMER = gql`
  ${STRIPE_CUSTOMER_FRAGMENT}
  query GetStripeCustomer($id: String!) {
    stripe_customers_by_pk(id: $id) {
      ...StripeCustomerFragment
    }
  }
`;

export const UPDATE_STRIPE_CUSTOMER = gql`
  ${STRIPE_CUSTOMER_FRAGMENT}
  mutation UpdateStripeCustomer($id: String!, $user_id: String!) {
    update_stripe_customers_by_pk(
      pk_columns: { id: $id }
      _set: { user_id: $user_id }
    ) {
      ...StripeCustomerFragment
    }
  }
`;

import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

interface ApolloProps {
  children: React.ReactNode;
}

const Apollo = ({ children }: ApolloProps) => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } =
    useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  let authLink;
  if (isAuthenticated) {
    authLink = setContext(async () => {
      const [token, claims] = await Promise.all([
        getAccessTokenSilently(),
        getIdTokenClaims(),
      ]);
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-hasura-role':
            claims?.['https://hasura.io/jwt/claims']['x-hasura-role'],
        },
      };
    });
  } else {
    authLink = setContext(() => {});
  }

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;

import { gql } from '@apollo/client';

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on teams {
    id
    created_at
    name
    users_teams_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TEAMS = gql`
  ${TEAM_FRAGMENT}
  query GetTeams {
    teams(order_by: { created_at: desc }) {
      ...TeamFragment
    }
  }
`;

export const GET_TEAM = gql`
  ${TEAM_FRAGMENT}
  query GetTeam($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      ...TeamFragment
      users_teams {
        created_at
        user {
          id
          name
          email
          last_seen
        }
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  ${TEAM_FRAGMENT}
  mutation UpdateTeam($id: uuid!, $changes: teams_set_input) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...TeamFragment
    }
  }
`;

export const INSERT_TEAM = gql`
  ${TEAM_FRAGMENT}
  mutation InsertTeam($name: String!) {
    insert_teams_one(object: { name: $name }) {
      ...TeamFragment
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation DeleteTeam($id: uuid!) {
    delete_teams_by_pk(id: $id) {
      id
    }
  }
`;

export const INSERT_USERS_TEAMS = gql`
  mutation InsertUsersTeams($team_id: uuid!, $user_id: String!) {
    insert_users_teams_one(object: { team_id: $team_id, user_id: $user_id }) {
      team_id
    }
  }
`;

export const DELETE_USERS_TEAMS = gql`
  mutation DeleteUsersTeams($team_id: uuid!, $user_id: String!) {
    delete_users_teams(
      where: { team_id: { _eq: $team_id }, user_id: { _eq: $user_id } }
    ) {
      affected_rows
    }
  }
`;

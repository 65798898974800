import { Drawer as AntDrawer, DrawerProps } from 'antd';

const Drawer = ({ children, ...props }: DrawerProps) => {
  return (
    <AntDrawer
      width={window.innerWidth > 1080 ? 1080 : window.innerWidth}
      visible
      bodyStyle={{ padding: 0, backgroundColor: '#f6f7f7' }}
      // eslint-disable-next-line
      {...props}
    >
      {children}
    </AntDrawer>
  );
};

export default Drawer;

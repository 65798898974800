import { gql } from '@apollo/client';
import { ACTIVITY_FRAGMENT } from './activity';

export const USER_FRAGMENT = gql`
  fragment UserFragment on users {
    id
    created_at
    email
    family_name
    given_name
    mobile
    last_seen
    name
    nickname
    webhook_url
  }
`;

export const GET_USERS = gql`
  ${USER_FRAGMENT}
  query GetUsers($search: String = "%") {
    users(
      where: {
        _or: [
          { name: { _ilike: $search } }
          { zip_codes: { zip_code: { id: { _ilike: $search } } } }
        ]
      }
      order_by: { email: asc, created_at: desc }
    ) {
      ...UserFragment
      properties_aggregate {
        aggregate {
          count
          max {
            created_at
          }
        }
      }
      zip_codes_aggregate {
        aggregate {
          count
        }
        nodes {
          zip_code {
            population
            sqmi
          }
        }
      }
      customers {
        subscriptions_aggregate(where: { status: { _eq: "active" } }) {
          aggregate {
            count
            max {
              current_period_end
            }
          }
        }
        invoices_aggregate {
          aggregate {
            sum {
              amount_paid
            }
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  ${USER_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  query GetUser($id: String!) {
    users_by_pk(id: $id) {
      ...UserFragment
      zip_codes {
        zip_code {
          id
          population
          pop_sqmi
          po_name
          sqmi
          state
          shape__are
          shape__len
          zip_code
          centroid
        }
      }
      activities(order_by: { created_at: desc }) {
        id
        ...ActivityFragment
      }
    }
  }
`;

export const UPDATE_USER = gql`
  ${USER_FRAGMENT}
  mutation UpdateUser($id: String!, $changes: users_set_input) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...UserFragment
    }
  }
`;

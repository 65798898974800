// This component shows a map of all zip codes colored
// by the number of agents in that zip code.
// It also shows a list of all agents in the zip code
// that the mouse is hovering over.

import React, { useRef, useEffect, useState } from 'react';
import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useQuery } from '@apollo/client';
import { GET_AGENTS_MAP } from '../../graphql/zipCodes';
import { GetAgentsMapQuery } from '../../generated/graphql';

// @ts-ignore-next-line
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  'pk.eyJ1Ijoic3Bpbmd3dW4iLCJhIjoiY2l3NHpjaWkzMDAwejJ0cnMyMHI0empsYiJ9._rYClg-PkZKrwdA2F7ucOw';

const AgentsMap = () => {
  const mapContainer = useRef(null);
  const map = useRef<Map | null>(null);
  const [zipCodesLoaded, setZipCodesLoaded] = useState(false);

  // UseQuery to get all agents
  const { data, loading, error } = useQuery<GetAgentsMapQuery>(GET_AGENTS_MAP);

  // Initialize map when component mounts
  useEffect(() => {
    if (!mapContainer.current) return; // no map container
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74, 40.7],
      zoom: 6,
      projection: {
        name: 'globe',
      },
    });
  }, [mapContainer]);

  // Add event listeners
  useEffect(() => {
    if (!map) return; // no map
    if (!map.current) return; // wait for map to initialize

    map.current.on('load', () => {
      if (!map) return; // no map
      if (!map.current) return; // wait for map to initialize

      map.current.resize();

      map.current.setFog({});

      // Add source tileset
      map.current.addSource('zipcodes', {
        type: 'vector',
        url: 'mapbox://spingwun.0r1tzit7',
      });
      // Add zip centroids tileset for placing labels
      map.current.addSource('zipcodes-centroids', {
        type: 'vector',
        url: 'mapbox://spingwun.6n45usp1',
      });

      // Add fill layer
      map.current.addLayer({
        id: 'zipcodes-fill',
        type: 'fill',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'fill-color': '#455A64',
          'fill-opacity': 0.2,
        },
        minzoom: 0,
      });

      // Add outline layer
      map.current.addLayer({
        id: 'zipcodes-outline',
        type: 'line',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'line-color': 'white',
          'line-width': 0.5,
        },
        minzoom: 7,
      });

      // Add post office name labels below zip code labels
      map.current.addLayer({
        id: 'postoffice-label',
        type: 'symbol',
        source: 'zipcodes-centroids',
        'source-layer': 'us_zips_centroids-1sbaed',
        layout: {
          'text-field': [
            'concat',
            ['get', 'PO_NAME'],
            ' (',
            ['get', 'STATE'],
            ')',
          ],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 11,
          'text-offset': [0, 2],
        },
        paint: {
          'text-color': 'white',
        },
        minzoom: 8,
      });

      // Add zip code labels
      map.current.addLayer({
        id: 'zipcodes-label',
        type: 'symbol',
        source: 'zipcodes-centroids',
        'source-layer': 'us_zips_centroids-1sbaed',
        layout: {
          'text-field': ['get', 'ZIP_CODE'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 14,
        },
        paint: {
          'text-color': 'white',
        },
        minzoom: 8,
      });
    });

    map.current.on('sourcedata', (e) => {
      if (e.isSourceLoaded && e.sourceId === 'zipcodes') {
        console.log('Zip Codes source loaded');
        setZipCodesLoaded(true);
      }
    });
  }, [map]);

  // Color polygons based on number of agents in zip code
  useEffect(() => {
    if (!map) return; // no map
    if (!map.current) return; // wait for map to initialize
    if (loading) return; // wait for data to load
    if (error) return; // wait for data to load
    if (!data) return; // wait for data to load
    if (!zipCodesLoaded) return; // wait for zip codes to load

    console.log("Drawing zip codes based on agents' zip codes");

    // Iterate 5x to get 5 colors
    for (let i = 1; i <= 5; i++) {
      // Add fill layer
      map.current.addLayer({
        id: 'zipcodes-fill-' + i,
        type: 'fill',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'fill-color': 'red',
          'fill-opacity': i / 5,
        },
        filter: [
          'in',
          'zip_code',
          ...data.zip_codes
            .filter(
              (zip) =>
                (zip.users_zip_codes_aggregate.aggregate?.count || 1) >= i
            )
            .map((zip) => zip.zip_code),
        ],
        minzoom: 0,
      });
      map.current.moveLayer('zipcodes-fill-' + i, 'zipcodes-outline');
    }

    // Add postcode click event to show popup with number of agents
    map.current.on('click', 'zipcodes-fill', (e) => {
      const zipCode = (e.features || [])[0]?.properties?.zip_code;

      const zip = data.zip_codes.find((zip) => zip.zip_code === zipCode);

      new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(
          `
          <h3>${zipCode}</h3>
          <ul>
            ${(zip?.users_zip_codes_aggregate.nodes || [])
              .map((userZipCode) => `<li>${userZipCode.user.name}</li>`)
              .join('')}
          </ul>
        `
        )
        .addTo(map.current as Map);
    });
  }, [map, data, loading, error, zipCodesLoaded]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={mapContainer}
      className="map-container"
    />
  );
};

export default AgentsMap;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import Apollo from './providers/Apollo';
import Auth from './providers/Auth';
import MyLayout from './providers/Layout';

import Routes from './routes/Routes';

const App = () => {
  return (
    <Router>
      <Auth>
        <Apollo>
          <MyLayout>
            <Routes />
          </MyLayout>
        </Apollo>
      </Auth>
    </Router>
  );
};

export default App;

import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Menu, Dropdown, Button, Layout } from 'antd';
import {
  MenuOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import MySearch from './Search';

interface MyHeaderProps {
  isMobile: boolean;
  setOpen: (x: boolean) => void;
}

const MyHeader = ({ isMobile, setOpen }: MyHeaderProps) => {
  const { logout, user } = useAuth0();
  const { Header } = Layout;

  const menu = (
    <Menu>
      <Menu.Item icon={<UserOutlined />} className="ant-menu-item">
        <Link to={`/users/${user?.email}`}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        icon={<LogoutOutlined />}
        danger
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ display: 'flex', alignItems: 'center' }}>
      {isMobile && (
        <Button onClick={() => setOpen(true)}>
          <MenuOutlined />
        </Button>
      )}

      <MySearch style={{ flex: '1 1 0%' }} />
      <Dropdown overlay={menu}>
        <Button
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
          style={{ paddingLeft: 5 }}
        >
          <Avatar
            style={{ marginRight: 10 }}
            size="small"
            src={user?.picture}
          />
          {user?.given_name || user?.nickname}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Header>
  );
};

export default MyHeader;

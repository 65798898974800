import { Result, Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { LockOutlined } from '@ant-design/icons';

const Unauthorized = () => {
  const { logout } = useAuth0();
  return (
    <Result
      title="Unauthorized"
      subTitle="Please contact support to activate your account."
      status="warning"
      icon={<LockOutlined />}
      extra={
        <>
          <Button
            type="primary"
            key="console"
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
          <Button
            type="default"
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            Log Out
          </Button>
        </>
      }
    />
  );
};

export default Unauthorized;

import { useQuery } from '@apollo/client';
import { Badge, Table, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { format, TDate } from 'timeago.js';
import { GET_STRIPE_CUSTOMERS } from '../../graphql/stripeCustomer';
import { GetStripeCustomersQuery } from '../../generated/graphql';
type Customer = GetStripeCustomersQuery['stripe_customers'][0];

const columns = [
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: 100,
    ellipsis: true,
    render: (t: TDate) => (
      <Tooltip title={new Date(t).toLocaleDateString()}>
        {format(t, 'en_US')}
      </Tooltip>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
    ellipsis: true,
    render: (t: string, r: Customer) => (
      <>
        <Badge
          status={
            r.subscriptions_aggregate.aggregate?.count ? 'success' : 'error'
          }
        />
        {t}
      </>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Stripe Account',
    dataIndex: ['account', 'name'],
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Subscriptions',
    dataIndex: ['subscriptions_aggregate', 'aggregate', 'count'],
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Period end',
    dataIndex: [
      'subscriptions_aggregate',
      'aggregate',
      'max',
      'current_period_end',
    ],
    ellipsis: true,
    width: 150,
  },
  {
    title: 'Total spent',
    dataIndex: ['invoices_aggregate', 'aggregate', 'sum', 'amount_paid'],
    width: 150,
    ellipsis: true,
    render: (t: number) => (t ? (t / 100).toLocaleString() : '-'),
  },
  {
    title: 'CRM user',
    dataIndex: ['user', 'name'],
    width: 150,
    ellipsis: true,
  },
];

function StripeCustomers() {
  const history = useHistory();
  const { data, loading, error } =
    useQuery<GetStripeCustomersQuery>(GET_STRIPE_CUSTOMERS);
  if (error) return <div>{error.message}</div>;

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table
        style={{ cursor: 'pointer', minWidth: 800 }}
        columns={columns}
        dataSource={data?.stripe_customers}
        loading={loading}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={(r) => ({
          onClick: () => history.push(`/stripe-customers/${r.id}`),
        })}
      />
    </div>
  );
}

export default StripeCustomers;

// This page shows a map using mapbox-gl and allows the user to select zip codes
// to add to the map. The user can also select a zip code to view the details
// of that zip code.

import React, { useRef, useEffect, useState } from 'react';

import mapboxgl, { Map } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ZipCodesSelect from '../../components/ZipCodesSelect';
import { Button, Col, Drawer, Row, Space, Table, Tag } from 'antd';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';

// @ts-ignore-next-line
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_TOKEN ||
  'pk.eyJ1Ijoic3Bpbmd3dW4iLCJhIjoiY2l3NHpjaWkzMDAwejJ0cnMyMHI0empsYiJ9._rYClg-PkZKrwdA2F7ucOw';

type Zip = {
  id: string;
  population: number;
  pop_sqmi: number;
  po_name: string;
  sqmi: number;
  state: string;
  shape__are: number;
  shape__len: number;
  zip_code: string;
  centroid: {
    type: string;
    coordinates: [number, number];
    crs: object;
  };
};

type ZipProps = {
  initialZips?: Zip[];
  onClose: () => void;
  onSelect?: (zip: Zip) => void;
  onDeselect?: (zip: Zip) => void;
};

const Zip: React.FC<ZipProps> = ({
  initialZips = [],
  onClose,
  onSelect = console.log,
  onDeselect = console.log,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(true);
  const mapContainer = useRef(null);
  const map = useRef<Map | null>(null);

  const [zips, _setZips] = useState<Zip[]>(initialZips);
  const zipsRef = useRef(zips);
  const setZips = (data: Zip[]) => {
    zipsRef.current = data;
    _setZips(data);
  };
  // Handle zip code (de-)selection
  const handleZip = (zip: Zip) => {
    console.log('handleZip', zip);
    if (zipsRef.current.map((z) => z.id).includes(zip.id)) {
      setZips(zipsRef.current.filter((z) => z.id !== zip.id));
      onDeselect(zip);
    } else {
      setZips([...zipsRef.current, zip]);
      onSelect(zip);
      if (zip.centroid) {
        map?.current?.flyTo({
          center: zip.centroid.coordinates,
          speed: 3,
          zoom: 10,
        });
      }
    }
    map?.current?.setFilter('zipcodes-selected', [
      'in',
      'id',
      ...zipsRef.current.map((z) => z.id),
    ]);
  };

  // Initialize map when component mounts
  useEffect(() => {
    if (!mapContainer.current) return; // no map container
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74, 40.7],
      zoom: initialZips.length ? 6 : 12,
      projection: {
        name: 'globe',
      },
    });
  }, [mapContainer]);

  // Add event listeners
  useEffect(() => {
    if (!map) return; // no map
    if (!map.current) return; // wait for map to initialize

    // Dump feature properties on click
    map.current.on('click', 'zipcodes-fill', (e) => {
      if (e?.features?.length) {
        console.log(e.features[0].properties);
        const zip = e.features[0].properties as Zip;
        handleZip(zip);
      }
    });

    map.current.on('load', () => {
      if (!map) return; // no map
      if (!map.current) return; // wait for map to initialize

      map.current.resize();

      map.current.setFog({});

      // Calculate the bounds of the map based on the zip codes centroids
      if (initialZips.length) {
        const bounds = new mapboxgl.LngLatBounds();
        initialZips.map((z) => bounds.extend(z.centroid.coordinates));
        map.current.fitBounds(bounds, {
          maxZoom: 10,
        });
      }

      // Add source tileset
      map.current.addSource('zipcodes', {
        type: 'vector',
        url: 'mapbox://spingwun.0r1tzit7',
      });

      // Add zip centroids tileset for placing labels
      map.current.addSource('zipcodes-centroids', {
        type: 'vector',
        url: 'mapbox://spingwun.6n45usp1',
      });

      // Add fill layer
      map.current.addLayer({
        id: 'zipcodes-fill',
        type: 'fill',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'fill-color': '#455A64',
          'fill-opacity': 0.4,
        },
        minzoom: 7,
      });

      // Add hover layer
      map.current.addLayer({
        id: 'zipcodes-hover',
        type: 'fill',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'fill-color': '#1976D2',
          'fill-opacity': 0.4,
        },
        filter: ['in', 'zip_code', ''],
        minzoom: 8,
      });

      // Add selected layer
      map.current.addLayer({
        id: 'zipcodes-selected',
        type: 'fill',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'fill-color': '#1976D2',
          'fill-opacity': 0.8,
        },
        filter: ['in', 'zip_code', ...zipsRef.current.map((z) => z.zip_code)],
        minzoom: 6,
      });

      // Add outline layer
      map.current.addLayer({
        id: 'zipcodes-outline',
        type: 'line',
        source: 'zipcodes',
        'source-layer': 'us_zips-3jeonb',
        paint: {
          'line-color': 'white',
          'line-width': 1,
        },
        minzoom: 7,
      });

      // Add post office name labels below zip code labels
      map.current.addLayer({
        id: 'postoffice-label',
        type: 'symbol',
        source: 'zipcodes-centroids',
        'source-layer': 'us_zips_centroids-1sbaed',
        layout: {
          'text-field': [
            'concat',
            ['get', 'PO_NAME'],
            ' (',
            ['get', 'STATE'],
            ')',
          ],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 11,
          'text-offset': [0, 2],
        },
        paint: {
          'text-color': 'white',
        },
        minzoom: 8,
      });

      // Add zip code labels
      map.current.addLayer({
        id: 'zipcodes-label',
        type: 'symbol',
        source: 'zipcodes-centroids',
        'source-layer': 'us_zips_centroids-1sbaed',
        layout: {
          'text-field': ['get', 'ZIP_CODE'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 14,
        },
        paint: {
          'text-color': 'white',
        },
        minzoom: 8,
      });
    });

    // Add hover event
    map.current.on('mousemove', 'zipcodes-fill', (e) => {
      if (e?.features?.length) {
        map?.current?.setFilter('zipcodes-hover', [
          'in',
          'zip_code',
          e?.features[0].properties?.zip_code,
        ]);
      }
    });

    map.current.on('mouseleave', 'zipcodes-fill', () => {
      map?.current?.setFilter('zipcodes-hover', ['in', 'zip_code', '']);
    });

    const resizer = new ResizeObserver(() => {
      if (!map.current) return; // no map
      map.current.resize();
    });
    if (mapContainer.current) resizer.observe(mapContainer.current);
  }, [map]);

  // Create a table of selected zip codes using Ant Design
  const columns = [
    {
      title: 'Zip',
      dataIndex: 'zip_code',
      key: 'zip_code',
      width: 75,
      render: (_: any, record: any) => (
        <a
          onClick={() => {
            map.current?.flyTo({
              center: record.centroid?.coordinates,
              zoom: 10,
              speed: 3,
            });
          }}
        >
          {record.zip_code}
        </a>
      ),
    },
    {
      title: 'P.O. Name',
      dataIndex: 'po_name',
      key: 'zip_code',
      ellipsis: true,
      width: 120,
      render: (_: any, record: any) =>
        record.po_name + ' (' + record.state + ')',
    },
    {
      title: 'Population',
      dataIndex: 'population',
      key: 'population',
      align: 'right' as any,
      ellipsis: true,
      width: 100,
      render: (pop?: Number) => pop?.toLocaleString() || '-',
    },
    {
      title: 'Area (sqm)',
      dataIndex: 'sqmi',
      key: 'sqmi',
      align: 'right' as any,
      ellipsis: true,
      width: 75,
      render: (area?: number) =>
        area ? Math.round(area).toLocaleString() : '-',
    },
    {
      key: 'action',
      width: 20,
      align: 'center' as any,
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="link"
            danger
            size="small"
            onClick={() => handleZip(record)}
          >
            <DeleteOutlined color="red" />
          </Button>
        </Space>
      ),
    },
  ];

  const data = zipsRef.current.sort(
    (a, b) => parseInt(a.zip_code) - parseInt(b.zip_code)
  );

  return (
    <Drawer
      placement="right"
      closable={false}
      visible={drawerVisible}
      onClose={() => {
        setDrawerVisible(false);
        setTimeout(onClose, 300);
      }}
      width={window.innerWidth >= 576 ? 'calc(100vw - 60px)' : '100vw'}
      bodyStyle={{ padding: 0, backgroundColor: '#f0f2f5' }}
    >
      <Row style={{ height: '100%' }}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 2 }}
          lg={{ span: 24, order: 2 }}
          xl={{ span: 12, order: 1 }}
          xxl={{ span: 8, order: 1 }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              overflow: 'hidden',
              padding: '12px',
            }}
          >
            <div>
              <ZipCodesSelect
                //mode="multiple"
                //value={zips}
                value={null}
                // onChange={(value: any) => handleZip(value)}
                onSelect={(value: any) => handleZip(value)}
                // onDeselect={(value: any) => handleZip(value.value)}
                style={{ width: '100%' }}
                placeholder="Search zip codes or cities"
                showSearch
              />
              <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
                style={{ marginTop: '12px' }}
                scroll={{
                  y:
                    window.innerWidth >= 1200
                      ? 'calc(100vh - 155px)'
                      : 'calc(50vh - 155px)',
                  x: true,
                }}
                summary={(pageData) => {
                  let totalPopulation = 0;
                  pageData.forEach(({ population }) => {
                    totalPopulation += population || 0;
                  });
                  const totalArea = pageData.reduce(
                    (acc, cur) => acc + cur.sqmi,
                    0
                  );
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <b>Total: {data.length}</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="right">
                          <b>{totalPopulation.toLocaleString()}</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">
                          <b>{Math.round(totalArea).toLocaleString()}</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 24, order: 1 }}
          xl={{ span: 12, order: 2 }}
          xxl={{ span: 16, order: 2 }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
            ref={mapContainer}
            className="map-container"
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default Zip;

import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { GetStripeAccountQuery } from '../../generated/graphql';
import { GET_STRIPE_ACCOUNT } from '../../graphql/stripeAccount';
import { Button, Drawer, Space } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const stripeEntities = [
  { type: 'products', label: 'Products', loadingTime: 3000 },
  { type: 'prices', label: 'Prices', loadingTime: 3000 },
  { type: 'customers', label: 'Customers', loadingTime: 5000 },
  { type: 'subscriptions', label: 'Subscriptions', loadingTime: 5000 },
  { type: 'invoices', label: 'Invoices', loadingTime: 10000 },
  { type: 'charges', label: 'Charges', loadingTime: 30000 },
];

const initialButtonStatus = stripeEntities.reduce(
  (acc, { type }) => ({ ...acc, [type]: 'idle' }),
  {}
);

const StripeAccount = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [drawerVisible, setDrawerVisible] = useState(true);
  const [buttonStatus, setButtonStatus] = useState<any>(initialButtonStatus);

  const { error, data, loading } = useQuery<GetStripeAccountQuery>(
    GET_STRIPE_ACCOUNT,
    {
      variables: { id },
    }
  );

  const acc = data?.stripe_accounts_by_pk;

  const onSyncButtonClick = (type: string) => {
    setButtonStatus({ ...buttonStatus, [type]: 'loading' });
    setTimeout(() => {
      setButtonStatus({ ...buttonStatus, [type]: 'success' });
    }, stripeEntities.find((e) => e.type === type)?.loadingTime);
    const endpoint = `${process.env.REACT_APP_FX_URL}/stripe_sync-background/${acc?.id}/${type}`;
    fetch(endpoint, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Drawer
      open={drawerVisible}
      closable={false}
      onClose={() => {
        setDrawerVisible(false);
        setTimeout(() => history.push('/stripe-accounts'), 300);
      }}
    >
      {(() => {
        if (loading) return <div>Loading...</div>;
        if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
        if (!data) return <div>Not found</div>;

        return (
          <>
            <h1>STRIPE ACCOUNT: {acc?.name}</h1>
            <h2>STRIPE ID: {acc?.id}</h2>
            <Space direction="vertical" size="middle">
              {stripeEntities.map(({ type, label }) => (
                <Button
                  key={type}
                  type="primary"
                  loading={buttonStatus[type] === 'loading'}
                  onClick={() => onSyncButtonClick(type)}
                  icon={
                    buttonStatus[type] === 'success' ? (
                      <CheckCircleFilled />
                    ) : undefined
                  }
                  disabled={
                    buttonStatus[type] === 'success' ||
                    Object.values(buttonStatus).some((s) => s === 'loading')
                  }
                >
                  Sync {label}
                </Button>
              ))}
            </Space>
          </>
        );
      })()}
    </Drawer>
  );
};

export default StripeAccount;

import { gql } from '@apollo/client';

import { ACTIVITY_FRAGMENT } from './activity';
import { ACTIVITY_TYPE_FRAGMENT } from './activityType';

export const PROPERTY_FRAGMENT = gql`
  fragment PropertyFragment on properties {
    id
    created_at
    updated_at
    property_type
    formatted_address
    route
    street_number
    unit
    postal_code
    locality
    state
    state_long
    country
    lat
    lng
    appraisal_reason
    occupation_type
    mobile
    user_relation
    sale_horizon
    perceived_value
    given_name
    family_name
    email
    sms_sent_at
    sms_verified_at
    low_estimate
    value_estimate
    high_estimate
    landing_page_url
    referrer_url
    _fbp
    _fbc
    navigator_user_agent
    ip
    utm_medium
    utm_source
    utm_campaign
    utm_term
    utm_content
    fbclid
    gclid
    agent {
      id
      name
      webhook_url
    }
  }
`;

export const GET_PROPERTIES = gql`
  ${PROPERTY_FRAGMENT}
  query GetProperties($where: properties_bool_exp, $offset: Int = 0) {
    properties_aggregate(where: $where, offset: $offset) {
      aggregate {
        count
      }
    }
    properties(
      where: $where
      limit: 100
      offset: $offset
      order_by: { created_at: desc }
    ) {
      ...PropertyFragment
    }
  }
`;

export const INSERT_PROPERTY = gql`
  ${PROPERTY_FRAGMENT}
  mutation InsertProperty($object: properties_insert_input!) {
    insert_properties_one(object: $object) {
      ...PropertyFragment
    }
  }
`;

export const UPDATE_PROPERTY = gql`
  ${PROPERTY_FRAGMENT}
  mutation UpdateProperty($id: uuid!, $changes: properties_set_input!) {
    update_properties_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ...PropertyFragment
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation DeleteProperty($id: uuid!) {
    delete_properties_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_PROPERTY = gql`
  ${PROPERTY_FRAGMENT}
  ${ACTIVITY_FRAGMENT}
  query GetProperty($id: uuid!) {
    properties_by_pk(id: $id) {
      ...PropertyFragment
      estateds(order_by: { created_at: desc }, limit: 1) {
        response
      }
      realtors(order_by: { created_at: desc }, limit: 1) {
        response
      }
      zillows(order_by: { created_at: desc }, limit: 1) {
        response
      }
      activities(order_by: { created_at: desc }) {
        id
        ...ActivityFragment
      }
    }
  }
`;

import { useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import type { LabeledValue } from 'antd/lib/select';
import { useLazyQuery } from '@apollo/client';

import { GET_ZIP_CODES } from '../graphql/zipCodes';
import { GetZipCodesQuery } from '../generated/graphql';

const { Option } = Select;

interface ZipCodeSelectProps extends SelectProps {
  onChange?: (value: any) => void;
  onSelect?: (value: any) => void;
}

const ZipCodeSelect = ({
  onChange,
  onSelect,
  placeholder = 'Add zip codes',
  ...props
}: ZipCodeSelectProps) => {
  const [getZipCodes, { data }] = useLazyQuery<GetZipCodesQuery>(GET_ZIP_CODES);
  const [fetching, setFetching] = useState(false);

  const handleSearch = (v: string) => {
    setFetching(true);
    getZipCodes({
      variables: {
        _ilike: `%${v}%`,
      },
    }).then(() => setFetching(false));
  };

  const handleSelect = (value: LabeledValue) => {
    console.log('handleSelect', value);
    const zipCode = data?.zip_codes.find((z) => z.id === value.key);
    console.log({ value, zipCode });
    onSelect?.(zipCode);
  };

  return (
    <Select
      showSearch
      placeholder={placeholder}
      //optionFilterProp="children"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      labelInValue
      onSearch={handleSearch}
      onSelect={handleSelect}
      allowClear
      fieldNames={{ label: 'label', value: 'value' }}
      // @ts-ignore
      options={data?.zip_codes.map((z) => ({
        key: z.id,
        label: `${z.zip_code} - ${z.po_name} (${z.state})`,
        value: `${z.zip_code} - ${z.po_name} (${z.state})`,
      }))}
      {...props}
    />
  );
};

export default ZipCodeSelect;

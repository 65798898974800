import { gql } from '@apollo/client';

export const STRIPE_ACCOUNT_FRAGMENT = gql`
  fragment StripeAccountFragment on stripe_accounts {
    id
    name
  }
`;

export const GET_STRIPE_ACCOUNTS = gql`
  ${STRIPE_ACCOUNT_FRAGMENT}
  query GetStripeAccounts {
    stripe_accounts {
      ...StripeAccountFragment
      id
      name
      subscriptions_aggregate {
        aggregate {
          count
        }
      }
      customers_aggregate {
        aggregate {
          count
        }
      }
      invoices_aggregate(where: { status: { _eq: "paid" } }) {
        aggregate {
          sum {
            amount_paid
          }
        }
      }
      charges_aggregate(where: { status: { _eq: "succeeded" } }) {
        aggregate {
          sum {
            amount_captured
          }
        }
      }
    }
  }
`;

export const GET_STRIPE_ACCOUNT = gql`
  ${STRIPE_ACCOUNT_FRAGMENT}
  query GetStripeAccount($id: String!) {
    stripe_accounts_by_pk(id: $id) {
      ...StripeAccountFragment
    }
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';
import { LockTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

interface AuthProps {
  children: React.ReactNode;
}

const Loader = ({ children }: AuthProps) => {
  const { isLoading } = useAuth0();
  if (isLoading)
    return (
      <Result
        icon={<LoadingOutlined spin />}
        title={
          <>
            <LockTwoTone twoToneColor="#52c41a" style={{ marginRight: 10 }} />
            Authentication in progress
          </>
        }
      />
    ) as JSX.Element;
  return <>{children}</>;
};

const Auth = ({ children }: AuthProps) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain || ''}
      clientId={clientId || ''}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      cacheLocation="localstorage"
    >
      <Loader>{children}</Loader>
    </Auth0Provider>
  );
};

export default Auth;

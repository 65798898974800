import { gql } from '@apollo/client';

import { ACTIVITY_TYPE_FRAGMENT } from './activityType';

export const ACTIVITY_FRAGMENT = gql`
  ${ACTIVITY_TYPE_FRAGMENT}
  fragment ActivityFragment on activities {
    id
    created_at
    created_by
    done_at
    due_at
    description
    activity_type_id
    created_by
    property_id
    updated_at
    activity_type {
      id
      ...ActivityTypeFragment
    }
    created_by_user {
      id
      name
      given_name
      family_name
    }
    property {
      id
      given_name
      family_name
      formatted_address
    }
  }
`;

export const GET_ACTIVITIES = gql`
  ${ACTIVITY_FRAGMENT}
  query GetActivities {
    activities(order_by: { created_at: desc }, limit: 100) {
      id
      ...ActivityFragment
    }
  }
`;

export const INSERT_ACTIVITY = gql`
  ${ACTIVITY_FRAGMENT}
  mutation InsertActivity($object: activities_insert_input!) {
    insert_activities_one(object: $object) {
      id
      ...ActivityFragment
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($id: uuid!, $changes: activities_set_input) {
    update_activities_by_pk(pk_columns: { id: $id }, _set: $changes) {
      id
      done_at
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($id: uuid!) {
    delete_activities_by_pk(id: $id) {
      id
    }
  }
`;

import { gql } from '@apollo/client';

export const ACTIVITY_TYPE_FRAGMENT = gql`
  fragment ActivityTypeFragment on activity_types {
    created_at
    id
    label
    emoji
  }
`;

export const GET_ACTIVITY_TYPES = gql`
  ${ACTIVITY_TYPE_FRAGMENT}
  query GetActivityTypes {
    activity_types(order_by: { created_at: desc }) {
      ...ActivityTypeFragment
    }
  }
`;

export const INSERT_ACTIVITY_TYPE = gql`
  ${ACTIVITY_TYPE_FRAGMENT}
  mutation InsertActivityType($object: activity_types_insert_input!) {
    insert_activity_types_one(object: $object) {
      ...ActivityTypeFragment
    }
  }
`;

export const DELETE_ACTIVITY_TYPE = gql`
  ${ACTIVITY_TYPE_FRAGMENT}
  mutation DeleteActivityType($id: String!) {
    delete_activity_types_by_pk(id: $id) {
      id
    }
  }
`;
